import React, { useEffect, useRef, useState } from "react";
import wkwp_logo from "../../assets/imgs/wkwp_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHome,
  faSignOut,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {
  const [nav, setNav] = useState(false);
  return (
    <>
      <div className="header">
        <div className="lt">
          <FontAwesomeIcon
            className="fa-bars"
            onClick={() => {
              setNav(true);
            }}
            icon={faBars}
          />
        </div>
        <img src={wkwp_logo} alt="" />
      </div>
      {nav && <MobileHeader setNav={setNav} />}
    </>
  );
}

const MobileHeader = (props) => {
  const pathname = window.location.pathname.split("/");

  return (
    <div className="mobheader">
      <FontAwesomeIcon
        onClick={() => {
          props.setNav(false);
        }}
        icon={faTimes}
        className="fa-times"
      />
      <img src={wkwp_logo} alt="" />
      <h1>MEL-MIS</h1>

      <hr />
      <Item
        txt="Home"
        url="/"
        active={pathname[1]}
        link=""
        icon={faHome}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Signout"
        active={pathname[1]}
        icon={faSignOut}
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
};

const Item = (params) => {
  return (
    <div
      onClick={() => {
        if (params.options?.length === 0) {
          window.location.href = params.url;
        }
        if (params.url == "/logout") {
          localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
          window.location.href = "/login";
        }
      }}
      onMouseEnter={() => {}}
      className={params.link == params.active ? "active" : "item"}
    >
      <FontAwesomeIcon icon={params.icon} />
      <p>{params.txt}</p>
    </div>
  );
};
