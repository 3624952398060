import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
import NotFound from "./Pages/404";
import AOS from "aos";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Login/ProtectedRoute";


function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<ProtectedRoute component={Home} />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
