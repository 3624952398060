import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import Main from "../components/Folders/Main";
export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [path, setPath] = useState(null);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  return (
    <div className="home">
      <div className="main">
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Main path={path} setPath={setPath} />}
          </div>
        </div>
      </div>
    </div>
  );
}
